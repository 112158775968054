import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ButtonVnt from "../components/button-vnt"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faGlobe,
  faLock,
  faMagic,
  faPalette,
  faCreditCard,
  faQuestionCircle,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons"

const TiendaEnLinea = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Add-to-Cart-cuate.png" }) {
        base
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Tienda en línea" />
      <div className="container page-wrapper">
        <div className="row mb-5">
          <div className="col-lg-6">
            <div className="service-img-wrapper">
              <Img
                fluid={data.file.childImageSharp.fluid}
                alt={data.file.base}
              />
            </div>
          </div>

          <div className="col-lg-6 col-xl-5 ml-xl-auto">
            <div className="special_description_content">
              <div className="section_heading">
                <h2 className="title-text">Tienda en línea.</h2>
                <div className={`mb-5 underline_shape`}></div>
              </div>

              <p className="paragpah-text">
                Te ofrecemos todo lo que necesitas para crear tu tienda en
                línea. Empieza a vender por internet y haz crecer tu negocio.
              </p>
              <h2 className="title-text">Experiencia en cada paso.</h2>
              <p className="paragpah-text">
                Aseguramos cada etapa de tu proyecto, nuestra experiencia y
                metodología de trabajo nos permiten asegurar el desarrollo y
                despliegue de tu sitio, reduciendo tiempos de entrega y
                aumentando la calidad requerida por nuestros clientes.
              </p>
            </div>
            <div className="mt-4">
              <ButtonVnt
                linkTo="/#contact-us"
                textLabel="¿Necesitas más información?"
                buttonType="default"
              ></ButtonVnt>
            </div>
          </div>
        </div>

        <div
          style={{
            margin: "0 20px",
          }}
        >
          <div className="row">
            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div
                style={{
                  display: "inline-flex",
                  fontSize: "15px",
                }}
              >
                <FontAwesomeIcon size="2x" icon={faGlobe} />
                <h5 className="ml-4">Hosting y Dominio</h5>
              </div>
              <p>Te regalamos 1 año de hosting y dominio.</p>
            </div>

            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div
                style={{
                  display: "inline-flex",
                  fontSize: "15px",
                }}
              >
                <FontAwesomeIcon size="2x" icon={faLock} />
                <h5 className="ml-4">Sitio seguro.</h5>
              </div>
              <p>
                Certificado de seguridad SSL, que garantiza que la información
                de sus usuarios esta protegida.
              </p>
            </div>

            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div
                style={{
                  display: "inline-flex",
                  fontSize: "15px",
                }}
              >
                <FontAwesomeIcon size="2x" icon={faMagic} />
                <h5 className="ml-4">Panel de administración.</h5>
              </div>
              <p>
                Acceso a tu propio panel de administración para gestionar tus
                productos, clientes, etc.
              </p>
            </div>

            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div
                style={{
                  display: "inline-flex",
                  fontSize: "15px",
                }}
              >
                <FontAwesomeIcon size="2x" icon={faPalette} />
                <h5 className="ml-4">Plantillas lista para usar.</h5>
              </div>
              <p>
                Diseños para tu tienda que se verán perfectos en cualquier
                dispositivo.
              </p>
            </div>

            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div
                style={{
                  display: "inline-flex",
                  fontSize: "15px",
                }}
              >
                <FontAwesomeIcon size="2x" icon={faCreditCard} />
                <h5 className="ml-4">Pasarela de pago.</h5>
              </div>
              <p>Gestión de pagos con nuestros aliados PAYU o PAYPAL.</p>
            </div>

            <div className="col-12 col-sm-6 col-lg-4 mb-4">
              <div
                style={{
                  display: "inline-flex",
                  fontSize: "15px",
                }}
              >
                <FontAwesomeIcon size="2x" icon={faQuestionCircle} />
                <h5 className="ml-4">Soporte.</h5>
              </div>
              <p>
                Capacitación para que seas capaz de administrar tu tienda de
                manera fácil desde tu propio equipo y en cualquier momento.
              </p>
            </div>
          </div>

          <div
            className="pricing-plane-area section_padding_100_70 clearfix"
            id="pricing"
          >
            <div className="row">
              <div className="col-12">
                <div className="section-heading text-center">
                  <h2>Planes Ecommerce</h2>
                  <div className="line-shape"></div>
                </div>
              </div>
            </div>

            <div className="text-center mb-4">
              <div className="">
                <label className="toggler toggler--is-active" id="filt-monthly">
                  Mes
                </label>
                <div className="toggle">
                  <input type="checkbox" id="switcher" className="check" />
                  <b className="b switch"></b>
                </div>
                <label className="toggler" id="filt-hourly">
                  Año
                </label>
              </div>
            </div>

            <div className="row no-gutters justify-content-center" id="monthly">
              <div className="col-11 col-md-6 col-lg-4 m-3">
                <div className="single-price-plan text-center">
                  <div className="package-plan">
                    <h5>Starter</h5>
                    <div className="ca-price d-flex justify-content-center">
                      <span>$</span>
                      <h4>12 USD</h4>
                      <h4
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        Mes
                      </h4>{" "}
                      <br />
                    </div>
                    <h4
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      Facturado Mensual
                    </h4>
                  </div>

                  <div className="package-description jus">
                    <p className="feature-description">
                      Hosting &amp; dominio - 1 año{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Certificados SSL
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Panel de administración
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Inventario de productos ilimitados{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Creación de cupones de descuento{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Plantillas responsive - 4 diseños disponibles{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Configuración colores corporativos{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faTimes}
                      />
                    </p>
                    <p className="feature-description">
                      Blog{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faTimes}
                      />
                    </p>
                    <p className="feature-description">
                      Integración pasarela de pago PAYU o PAYPAL{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Transferencias 100 GB{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                  </div>

                  <div className="plan-button">
                    <ButtonVnt
                      linkTo="https://biz.payulatam.com/B0b41beCC2EB4FC"
                      textLabel="Seleccionar"
                      buttonType="switchplans-href"
                    ></ButtonVnt>
                  </div>
                </div>
              </div>

              <div className="col-11 col-md-6 col-lg-4 m-3">
                <div className="single-price-plan active text-center">
                  <div className="package-plan">
                    <h5>Advanced</h5>
                    <div className="ca-price d-flex justify-content-center">
                      <span>$</span>
                      <h4>15 USD</h4>
                      <h4
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        Mes
                      </h4>{" "}
                      <br />
                    </div>
                    <h4 style={{ fontSize: "12px", color: "#fff" }}>
                      Facturado Mensual
                    </h4>
                  </div>

                  <div className="package-description">
                    <p className="feature-description">
                      Hosting &amp; dominio - 1 año{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Certificados SSL{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Panel de administración{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Inventario de productos ilimitados{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Creación de cupones de descuento{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Plantillas responsive - 12 diseños disponibles{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Configuración colores corporativos{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Blog{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Integración pasarela de pago PAYU o PAYPAL{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Transferencias 300 GB{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                  </div>

                  <div className="plan-button">
                    <ButtonVnt
                      linkTo="https://biz.payulatam.com/B0b41be9CE4A25C"
                      textLabel="Seleccionar"
                      buttonType="switchplans-href"
                    ></ButtonVnt>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row no-gutters justify-content-center hide"
              id="yearly"
            >
              <div className="col-11 col-md-6 col-lg-4 m-3">
                <div className="single-price-plan text-center">
                  <div className="package-plan">
                    <h5>Starter</h5>
                    <div className="ca-price d-flex justify-content-center">
                      <span>$</span>
                      <h4>10.8 USD</h4>
                      <h4 style={{ fontSize: "15px" }}>Mes</h4>
                    </div>
                    <h4 style={{ fontSize: "12px" }}>
                      Facturado Anual - 10% Descuento
                    </h4>
                  </div>
                  <div className="package-description jus">
                    <p className="feature-description">
                      Hosting &amp; dominio - 1 año{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Certificados SSL{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Panel de administración{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Inventario de productos ilimitados{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Creación de cupones de descuento{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Plantillas responsive - 4 diseños disponibles{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Configuración colores corporativos{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faTimes}
                      />
                    </p>
                    <p className="feature-description">
                      Blog{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faTimes}
                      />
                    </p>
                    <p className="feature-description">
                      Integración pasarela de pago PAYU o PAYPAL{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Transferencias 100 GB{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                  </div>

                  <div className="plan-button">
                    <ButtonVnt
                      linkTo="https://biz.payulatam.com/B0b41be43E0AE5D"
                      textLabel="Seleccionar"
                      buttonType="switchplans-href"
                    ></ButtonVnt>
                  </div>
                </div>
              </div>

              <div className="col-11 col-md-6 col-lg-4 m-3">
                <div className="single-price-plan active text-center">
                  <div className="package-plan">
                    <h5>Advanced</h5>
                    <div className="ca-price d-flex justify-content-center">
                      <span>$</span>
                      <h4>13.5 USD</h4>
                      <h4 style={{ fontSize: "15px" }}>Mes</h4> <br />
                    </div>
                    <h4 style={{ fontSize: "12px", color: "#fff" }}>
                      Facturado Anual - 10% Descuento
                    </h4>
                  </div>
                  <div className="package-description">
                    <p className="feature-description">
                      Hosting &amp; dominio - 1 año{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Certificados SSL{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Panel de administración{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Inventario de productos ilimitados{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Creación de cupones de descuento{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Plantillas responsive - 12 diseños disponibles{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Configuración colores corporativos{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Blog{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Integración pasarela de pago PAYU o PAYPAL{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                    <p className="feature-description">
                      Transferencias 300 GB{" "}
                      <FontAwesomeIcon
                        className="float-right"
                        size="1x"
                        icon={faCheck}
                      />
                    </p>
                  </div>

                  <div className="plan-button">
                    <ButtonVnt
                      linkTo="https://biz.payulatam.com/B0b41be31A01273"
                      textLabel="Seleccionar"
                      buttonType="switchplans-href"
                    ></ButtonVnt>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-center">
              <p style={{ fontSize: "12px" }}>
                {" "}
                Al seleccionar un plan está aceptando nuestros{" "}
                <span>
                  <a
                    href="/terminos-condiciones"
                    target="_blank"
                    style={{ color: "#f7ce01" }}
                  >
                    Términos & Condiciones
                  </a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TiendaEnLinea
